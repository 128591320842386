import React from "react"
import { Layout } from "../../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const FullHeightImage = () => {
  return (
    <Layout>
      <GatsbySeo noindex={true} />
      <div className="max-w-5xl p-4">
        <h1 className="mt-6 font-serif text-2xl font-bold text-blue-800 md:mt-12">
          To Be On The Web
        </h1>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4 md:w-2/3">
            <div className="mt-6">
              To Be On The Web is sinds 2002 gespecialiseerd in het
              implementeren van websites en webapplicaties. Tevens kunt u bij
              ons terecht voor betrouwbare hosting.
            </div>
            <div className="mt-6">
              Wij werken met de volgende technologiën / oplossingen
              <ul className="mt-2 list-disc">
                <li className="ml-4">Wordpress</li>
                <li className="ml-4">Gridsome</li>
                <li className="ml-4">Laravel Framework</li>
                <li className="ml-4">Php</li>
              </ul>
            </div>
            <div className="mt-6">
              Onze klanten zijn met name particulieren en het midden- en
              kleinbedrijf (MKB).
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FullHeightImage
